export const checkStatus = (response) => {
  if (response.ok) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

export const toJSON = (response) => response.json();

export const toText = (response) => response.text();

export const toXML = (text) => {
  const parser = new DOMParser();

  return parser.parseFromString(text, "application/xml");
};
