import QRCode from "qrcode.react";

function QrcodeElement({ element }) {
  return (
    <div>
      <QRCode
        value={element.url}
        size={element.style.width}
        renderAs="svg"
        bgColor="transparent"
        fgColor={element.style.color}
      />
    </div>
  );
}

export default QrcodeElement;
