import { useEffect, useCallback } from "react";
import {
  add,
  intervalToDuration,
  formatDuration,
  differenceInSeconds,
  isValid,
  parse,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import PropTypes from "prop-types";
import { getTextContent, zeroDigit } from "../helpers";
import { useForceUpdate } from "../hooks";

function getTotalHours(duration) {
  return (duration.days || 0) * 24 +
    (duration.hours || 0)
}

function CountdownElement({ element }) {
  CountdownElement.propTypes = {
    element: PropTypes.object,
  };

  const forceUpdate = useForceUpdate();

  function getContent() {
    const { timezone, countdownDate, countdownItem } = element;
    const content = getCountdown(countdownDate, countdownItem, timezone);

    return getTextContent({ ...element, content });
  }

  function setCountdown(item, duration) {
    console.log(formatDuration(duration, { format: ['hours'] }))
    const days = zeroDigit(duration.days);
    const hours = zeroDigit(duration.hours);
    const minutes = zeroDigit(duration.minutes);
    const seconds = zeroDigit(duration.seconds);
    const totalHours = zeroDigit(getTotalHours(duration));

    switch (item) {
      case "all":
        return `${days}:${hours}:${minutes}:${seconds}`;
      case "days":
        return `${days}`;
      case "hours":
        return `${hours}`;
      case "minutes":
        return `${minutes}`;
      case "seconds":
        return `${seconds}`;
      case "totalHours":
        return `${totalHours}`;
      default:
        break;
    }
  }

  function getEndDate(date, timezone) {
    if (date) {
      const possibleFormats = [
        "dd/MM/yyyy",
        "yyyy-MM-dd",
        "MM/dd/yyyy",
        "dd/MM/yyyy HH:mm:ss",
        "dd/MM/yyyy HH:mm",
        "dd/MM/yyyy HH.mm",
      ];
      let parsedDate;
      for (const format of possibleFormats) {
        parsedDate = parse(date, format, new Date());
        if (isValid(parsedDate)) {
          break;
        }
      }
      if (!isValid(parsedDate)) {
        console.error("La fecha proporcionada no es válida:", date);
        return null;
      }
      return utcToZonedTime(parsedDate, timezone);
    }

    return utcToZonedTime(add(new Date(), { months: 1 }), timezone);
  }

  const getCountdown = useCallback((date, item, timezone) => {
    const now = utcToZonedTime(new Date(), timezone);
    const endDate = getEndDate(date, timezone);
    const diff = differenceInSeconds(endDate, now);
    const dur = intervalToDuration({
      start: now,
      end: endDate,
    });

    const countdown = setCountdown(item, dur);

    if (diff <= 0) {
      return "00";
    }

    return countdown;
  }, []);

  useEffect(() => {
    const { timezone, countdownDate, countdownItem } = element;
    const interval = setInterval(forceUpdate, 1000);

    getCountdown(countdownDate, countdownItem, timezone);

    return () => clearInterval(interval);
  }, [element, forceUpdate, getCountdown]);

  return (
    <div className="date" style={element.style}>
      {getContent()}
    </div>
  );
}

export default CountdownElement;
