import PropTypes from "prop-types";
import ReactPlayer from "react-player";

function StreamingElement({ element }) {
  StreamingElement.propTypes = {
    element: PropTypes.object,
  };

  const { style } = element;

  return (
    <div styleName="streaming">
      <ReactPlayer
        url={element.url}
        width={style.width}
        height={style.height}
        muted={true}
        playing
      />
    </div>
  );
}

export default StreamingElement;
