import { useEffect } from "react";
import PropTypes from "prop-types";
import URI from "urijs";
import { add } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { getTextContent, capitalize, getFnsFormat } from "../helpers";
import { DATE_LOCALES } from "../i18n/locales";
import { useForceUpdate } from "../hooks";

function DateElement({ element, lang }) {
  DateElement.propTypes = {
    element: PropTypes.object,
    lang: PropTypes.string,
  };

  const forceUpdate = useForceUpdate();

  function getOutput(datetime, dateUpdate, format, timezone) {
    return capitalize(
      formatInTimeZone(
        dateUpdate ? add(datetime, { days: dateUpdate }) : datetime,
        timezone,
        getFnsFormat(format),
        { locale: DATE_LOCALES[lang] || DATE_LOCALES.DEFAULT }
      )
    );
  }

  function getContent() {
    let { format, timezone, dateUpdate } = element;
    const query = URI(document.location.toString()).query(true);
    const datetime = query.dateTime ? new Date(query.dateTime) : new Date();

    return getTextContent({
      ...element,
      content: getOutput(datetime, dateUpdate, format, timezone),
    });
  }

  useEffect(() => {
    const interval = setInterval(forceUpdate, 1000);

    return () => clearInterval(interval);
  }, [forceUpdate]);

  return (
    <div styleName="date" style={element.style}>
      {getContent()}
    </div>
  );
}

export default DateElement;
