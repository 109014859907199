const FORMATS_TRANSLATOR = {
  LL: "PPP",
  "DD.MM.YYYY": "dd.MM.yyyy",
  "DD/MM/YYYY": "dd/MM/yyyy",
  "dddd, DD MMMM": "EEEE, dd MMMM",
  dddd: "EEEE",
  D: "d",
  MMM: "LLL",
  MMMM: "LLLL",
  YYYY: "u",
  LT: "HH:mm",
};

export function getFnsFormat(format) {
  return FORMATS_TRANSLATOR[format] || format;
}
