import * as fnsLocale from "date-fns/locale";

export const LOCALES = {
  ENGLISH: "en",
  SPANISH: "es",
  FRENCH: "fr",
  POLISH: "pl",
  ITALIAN: "it",
  GERMAN: "de",
  CATALAN: "ca",
  BASQUE: "eus",
  DEFAULT: "en",
};

const { es, enGB, fr, it, pl, de, ca, eu } = fnsLocale;

export const DATE_LOCALES = {
  [LOCALES.ENGLISH]: enGB,
  [LOCALES.SPANISH]: es,
  [LOCALES.FRENCH]: fr,
  [LOCALES.POLISH]: pl,
  [LOCALES.ITALIAN]: it,
  [LOCALES.GERMAN]: de,
  [LOCALES.CATALAN]: ca,
  [LOCALES.BASQUE]: eu,
  [LOCALES.DEFAULT]: enGB,
};
