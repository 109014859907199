import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import URI from "urijs";
import "./index.css";

const query = URI(document.location.toString()).query(true);
let hour = format(query.date || new Date(), "HH");
let daynight = "night";

//TODO: Revisar API de weather underground para asignar automáticamente horas de amanecer y ocaso
if (hour >= 7 && hour < 21) {
  daynight = "day";
}

const getName = (name) => {
  switch (name) {
    case "clear":
      if (daynight === "day") {
        return "day-sunny";
      } else {
        return "night-clear";
      }
    case "partly cloudy":
      return "cloud";
    case "drizzle":
      return "showers";
    case "haze":
      return "day-haze";
    case "sand":
      return "sandstorm";
    case "ash":
      return "volcano";
    case "squalls":
      return "cloudy-gusts";
    case "unknown":
      return "na";
    case "chance":
      return "thunderstorm";
    default:
      return name;
  }
};

const WeatherIcon = ({ name }) => <i className={`wi wi-${getName(name)}`}></i>;

WeatherIcon.propTypes = {
  name: PropTypes.string,
};

export default WeatherIcon;
