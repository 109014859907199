import { useState, useEffect, useCallback } from "react";
import URI from "urijs";
import { WeatherIcon } from "./CustomIcons";
import {
  fetchGeolocation,
  getTextContent,
} from "../helpers";
import { FormattedMessage } from "react-intl";

function WeatherElement({ element, location, player }) {
  const [geo, setGeo] = useState(null);

  function weatherForecast(forecast, condToday, date, feature) {
    let high = condToday.high;
    let low = condToday.low;
    let condForecast = condToday;

    if (forecast && forecast.length && date) {
      high = forecast[date].temperature.high;
      low = forecast[date].temperature.low;
      condForecast = forecast[date].condition;
    }
    const condition = Number(date) ? condForecast : condToday;

    switch (feature) {
      case "high":
        return `${typeof high === "number" ? Math.round(high) : 0}º`;
      case "low":
        return `${typeof low === "number" ? Math.round(low) : 0}º`;
      case "icon":
        return <WeatherIcon name={condition} />;
      case "condition":
        return <FormattedMessage id={condition}></FormattedMessage>
      default:
        return "";
    }
  }

  function getContent() {
    const query = URI(document.location.toString()).query(true);
    const { content, feature, dateUpdate } = element;

    if ((!element.location && location) || geo) {
      /**
       * wgXXX Overrides widget values using querystring parameters without altering conditional values.
       * wgWeather, wgCity, wgTemperature, wgUV, wgAqi
       *
       */
      const geoLocation = !element.location && location ? location : geo;
      const city = query.wgCity || geoLocation.city;
      const temperature = query.wgTemperature || geoLocation.temperature;
      const uv = query.wgUV || geoLocation.uv;
      const air = query.wgAqi || geoLocation.air;
      const pollen = query.wgPollen || geoLocation.pollen;
      const humidity = geoLocation.humidity;
      const wind_dir = geoLocation.wind_dir;
      const wind_kph = geoLocation.wind_kph;
      let textContent = "";

      switch (feature) {
        case "city":
          textContent = city;
          break;
        case "temperature":
          textContent =
            typeof temperature === "number"
              ? Math.round(temperature) + "º"
              : "0º";
          break;
        case "high":
          textContent = weatherForecast(
            geoLocation.forecast,
            geoLocation,
            dateUpdate,
            feature
          );
          break;
        case "low":
          textContent = weatherForecast(
            geoLocation.forecast,
            geoLocation,
            dateUpdate,
            feature
          );
          break;
        case "condition":
          
          if (typeof query.wgWeather !== "undefined") {
            textContent = query.wgWeather;
            
          } else {
            textContent = weatherForecast(
              geoLocation.forecast,
              geoLocation.condition,
              dateUpdate,
              feature
            );
            
          }
          break;
        case "uv":
          textContent = uv;
          break;
        case "air":
          textContent = air;
          break;
        case "pollen":
          textContent = pollen;
          break;
        case "humidity":
          textContent = humidity;
          break;
        case "wind_dir":
          textContent = wind_dir;
          break;
        case "wind_kph":
          textContent =
            typeof wind_kph === "number" ? Math.round(wind_kph) + "km/h" : "";
          break;
        case "icon":
          return weatherForecast(
            geoLocation.forecast,
            geoLocation.condition,
            dateUpdate,
            feature
          );
        default:
          break;
      }

      return getTextContent({ ...element, content: textContent });
    }

    return getTextContent({ ...element, content });
  }

  const fetchGeo = useCallback(() => {
    const { location, feature } = element;

    fetchGeolocation(location, feature, player).then((json) => setGeo(json));
  }, [element, player]);

  useEffect(() => {
    if (element.content) {
      fetchGeo();
    }

    const interval = setInterval(fetchGeo, 10 * 60 * 1000);

    return () => clearInterval(interval);
  }, [element.content, fetchGeo]);

  useEffect(() => {
    fetchGeo();
  }, [element.location, fetchGeo]);

  return (
    <div styleName="weather" style={element.style}>
      {getContent()}
    </div>
  );
}

export default WeatherElement;
