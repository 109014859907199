import URI from "urijs";
import { api, headers, checkStatus, toJSON } from "../helpers";

export function fetchGeolocation(address, feature, player) {
  const options = {
    method: "GET",
    headers: headers(),
  };
  let url = URI(`${api}/geo/location`);

  if (player && player.bsPlayerId) {
    url = URI(`${api}/geo/location/${player.bsPlayerId}`);
  }

  if (address) {
    url.addSearch("address", address);
  }

  if (feature) {
    url.addSearch("feature", feature);
  }

  return fetch(url.toString(), options).then(checkStatus).then(toJSON);
}
