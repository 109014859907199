import { LOCALES } from "./locales";

export const MESSAGES = {
  [LOCALES.ENGLISH]: {
    clear: "Clear",
    "partly cloudy": "Partly cloudy",
    cloudy: "Cloudy",
    rain: "Rain",
    drizzle: "Drizzle",
    hail: "Hail",
    thunderstorm: "Thunderstorm",
    snow: "Snow",
    fog: "Fog",
    haze: "Haze",
    sand: "Sand",
    dust: "Dust",
    smoke: "Smoke",
    ash: "Ash",
    squalls: "Squalls",
    unknown: "Unknown",
    undefined: "Unknown",
    chance: "Chance",
    "sport.period.1": "1st Half",
    "sport.period.2": "2nd Half",
    "sport.period.3": "1st Extra Time",
    "sport.period.4": "2nd Extra Time",
    "sport.period.5": "Penalties",
    "sport.period.10": "Half Time",
    "sport.period.11": "Half Time",
    "sport.period.12": "Half Time",
    "sport.period.13": "Half Time",
    "sport.period.14": "Final",
    "sport.period.16": "Not Started",
    "default.sport.match.status": "Pre-match",
  },
  [LOCALES.SPANISH]: {
    clear: "Despejado",
    "partly cloudy": "Parcialmente nublado",
    cloudy: "Nublado",
    rain: "Lluvia",
    drizzle: "Llovizna",
    hail: "Granizo",
    thunderstorm: "Tormenta eléctrica",
    snow: "Nieve",
    fog: "Niebla",
    haze: "Neblina",
    sand: "Arena",
    dust: "Polvo",
    smoke: "Humo",
    ash: "Ceniza",
    squalls: "Chubascos",
    unknown: "Desconocido",
    undefined: "Desconocido",
    chance: "Probabilidad",
    "sport.period.1": "1ª Parte",
    "sport.period.2": "2ª Parte",
    "sport.period.3": "1ª Prorr.",
    "sport.period.4": "2ª Prorr.",
    "sport.period.5": "Penaltis",
    "sport.period.10": "Descanso",
    "sport.period.11": "Descanso",
    "sport.period.12": "Descanso",
    "sport.period.13": "Descanso",
    "sport.period.14": "Final",
    "sport.period.16": "No comenzado",
    "default.sport.match.status": "Pre-partido",
  },
  [LOCALES.FRENCH]: {
    clear: "Dégagé",
    "partly cloudy": "Partiellement nuageux",
    cloudy: "Nuageux",
    rain: "Pluie",
    drizzle: "Bruine",
    hail: "Grêle",
    thunderstorm: "Orage",
    snow: "Neige",
    fog: "Brouillard",
    haze: "Brume",
    sand: "Sable",
    dust: "Poussière",
    smoke: "Fumée",
    ash: "Cendre",
    squalls: "Rafales",
    unknown: "Inconnu",
    undefined: "Inconnu",
    chance: "Chance",
    "sport.period.1": "1ère mi-temps",
    "sport.period.2": "2ème mi-temps",
    "sport.period.3": "1ère prolongation",
    "sport.period.4": "2ème prolongation",
    "sport.period.5": "Tirs au but",
    "sport.period.10": "Mi-temps",
    "sport.period.11": "Mi-temps",
    "sport.period.12": "Mi-temps",
    "sport.period.13": "Mi-temps",
    "sport.period.14": "Final",
    "sport.period.16": "Pas commencé",
    "default.sport.match.status": "Pré-match",
  },
  [LOCALES.POLISH]: {
    clear: "Bezchmurnie",
    "partly cloudy": "Częściowe zachmurzenie",
    cloudy: "Zachmurzenie",
    rain: "Deszcz",
    drizzle: "Mżawka",
    hail: "Grad",
    thunderstorm: "Burza z piorunami",
    snow: "Śnieg",
    fog: "Mgła",
    haze: "Zadymienie",
    sand: "Piasek",
    dust: "Kurz",
    smoke: "Dym",
    ash: "Popiół",
    squalls: "Porywy wiatru",
    unknown: "Nieznany",
    undefined: "Nieznany",
    chance: "Szansa",
    "sport.period.1": "Pierwsza połowa",
    "sport.period.2": "Druga połowa",
    "sport.period.3": "Pierwsze dogrywki",
    "sport.period.4": "Drugie dogrywki",
    "sport.period.5": "Rzuty karne",
    "sport.period.10": "Przerwa",
    "sport.period.11": "Przerwa",
    "sport.period.12": "Przerwa",
    "sport.period.13": "Przerwa",
    "sport.period.14": "Koniec",
    "sport.period.16": "Nie rozpoczęto",
    "default.sport.match.status": "Przedmeczowy",
  },
  [LOCALES.ITALIAN]: {
    clear: "Sereno",
    "partly cloudy": "Parzialmente nuvoloso",
    cloudy: "Nuvoloso",
    rain: "Pioggia",
    drizzle: "Pioggerella",
    hail: "Grandine",
    thunderstorm: "Temporale",
    snow: "Neve",
    fog: "Nebbia",
    haze: "Nebbia sottile",
    sand: "Sabbia",
    dust: "Polvere",
    smoke: "Fumo",
    ash: "Cenere",
    squalls: "Raffiche di vento",
    unknown: "Sconosciuto",
    undefined: "Sconosciuto",
    chance: "Possibilità",
    "sport.period.1": "Primo tempo",
    "sport.period.2": "Secondo tempo",
    "sport.period.3": "Primo tempo supplementare",
    "sport.period.4": "Secondo tempo supplementare",
    "sport.period.5": "Rigori",
    "sport.period.10": "Intervallo",
    "sport.period.11": "Intervallo",
    "sport.period.12": "Intervallo",
    "sport.period.13": "Intervallo",
    "sport.period.14": "Fine",
    "sport.period.16": "Non iniziato",
    "default.sport.match.status": "Pre-partita",
  },
  [LOCALES.GERMAN]: {
    clear: "Klar",
    "partly cloudy": "Teilweise bewölkt",
    cloudy: "Bewölkt",
    rain: "Regen",
    drizzle: "Nieselregen",
    hail: "Hagel",
    thunderstorm: "Gewitter",
    snow: "Schnee",
    fog: "Nebel",
    haze: "Dunst",
    sand: "Sand",
    dust: "Staub",
    smoke: "Rauch",
    ash: "Asche",
    squalls: "Windböen",
    unknown: "Unbekannt",
    undefined: "Unbekannt",
    chance: "Chance",
    "sport.period.1": "1. Halbzeit",
    "sport.period.2": "2. Halbzeit",
    "sport.period.3": "1. Verlängerung",
    "sport.period.4": "2. Verlängerung",
    "sport.period.5": "Elfmeterschießen",
    "sport.period.10": "Halbzeitpause",
    "sport.period.11": "Halbzeitpause",
    "sport.period.12": "Halbzeitpause",
    "sport.period.13": "Halbzeitpause",
    "sport.period.14": "Ende",
    "sport.period.16": "Nicht begonnen",
    "default.sport.match.status": "Vor dem Spiel",
  },
  [LOCALES.CATALAN]: {
    clear: "Clar",
    "partly cloudy": "Parcialment Ennuvolat",
    cloudy: "Ennuvolat",
    rain: "Pluja",
    drizzle: "Plugim",
    hail: "Calamarsa",
    thunderstorm: "Tempesta Elèctrica",
    snow: "Neu",
    fog: "Boira",
    haze: "Boirina",
    sand: "Sorra",
    dust: "Pols",
    smoke: "Fum",
    ash: "Cendra",
    squalls: "Xàfecs",
    unknown: "Desconegut",
  },
  [LOCALES.BASQUE]: {
    clear: "Oskarbi",
    "partly cloudy": "Tarteka estalia",
    cloudy: "Lainotsua",
    rain: "Euria",
    drizzle: "Zirimiria",
    hail: "Txingorra",
    thunderstorm: "Ekaitz elektrikoa",
    snow: "Elurra",
    fog: "Lainoa",
    haze: "Lainobera",
    sand: "Harea",
    dust: "Hautsa",
    smoke: "Kea",
    ash: "Errautsa",
    squalls: "Zaparradak",
    unknown: "Ezezaguna",
  },
};
